import axios from 'axios'
// import {baseApiUrl, apiUrl} from '@/constants/config'
import config from '@/constants/config'
import Cookies from 'js-cookie'
import {mapGetters} from 'vuex'
import { loginAppUrl } from '@/constants/config';

const axiosIns = axios.create({
  baseURL: config.apiUrl,
  withCredentials:true

})

axiosIns.interceptors.request.use(function (config) {

  config.params ={'auth_practice_id':`${sessionStorage.getItem('practice_id')}`}


  return config;
}, error => Promise.reject(error),

  axiosIns.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response.status === 403 || error.response.status === 401){
      localStorage.removeItem('isLoggedIn')
      window.location.href = loginAppUrl
    }
    return Promise.reject(error)
  })
);


export default axiosIns
