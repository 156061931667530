import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import profile from './profile';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    profile,
    verticalMenu,
  },
  state: {
    userWorkingHoursPerDay: 0,
    userDetails: '',
  },
  strict: process.env.DEV,
});
