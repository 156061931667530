export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      noAuth: true,
      title: 'Dashboard',

      // pageTitle: 'Home',
      // breadcrumb: [
      //   {
      //     text: 'Home',
      //     active: true,
      //   },
      // ],
    },
  },
]
