export default [
    {
        path: '/staff-bank/:id/:is_dummy',
        component: () => import('@/views/staff-bank/staff-profile/profile.vue'),
        name: 'staff-bank.single.dummy',
        meta: {
            noAuth: true,
            title: 'Staff Bank',
        },
    },
    {
        path: '/staff-bank/:id',
        component: () => import('@/views/staff-bank/staff-profile/profile.vue'),
        name: 'staff-bank.single',
        meta: {
            noAuth: true,
            title: 'My Account',
        },
    },
    {
        path: '/my-profile/:id',
        component: () => import('@/views/staff-bank/my-profile-tobe-removed/profile.vue'),
        name: 'staff-bank.single',
        meta: {
            noAuth: true,
            title: 'My Profile',
        },
    },
    {
        path: '/staff-bank',
        name: 'staff-bank',
        component: () => import('@/views/staff-bank/index.vue'),
        meta: {
            noAuth: true,
            title: 'Staff Bank',
        }
    }
]
