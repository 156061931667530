export default [
    {
        path: '/medicalpractice',
        name: 'medicalpractice',
        component: () => import('@/views/medicalpractice/index.vue'),
        meta: {
            noAuth: true,
            title: 'Medical Practice',
        }
    },
    {
        path: '/medicalpractice/view/document',
        name: 'medicalpractice',
        component: () => import('@/views/medicalpractice/view/documents.vue'),
        meta: {
            noAuth: true,
            title: 'Medical Practice',
        }
    }
]
